/* eslint-disable */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SecureImage from "../../assets/img/secure.webp";
import CheckoutModal from "../common/CheckoutModal";
import SuccessModal from "../common/SuccessModal";
import AuthLayout from "../Shared/AuthLayout";
import PreviewImageFiles from "../common/PreviewImageFiles";

const Checkout = () => {
    const SeletedPosterLists = useSelector((state) => state.allReducers.selectedPosters);
    const PosterMulti = useSelector((state) => state.allReducers.selectPosterMulti);
    const { accessToken, is_login } = useSelector((state) => state.allReducers);
    const [ checkoutPoup, setCheckoutPoup ] = useState({
        open: false,
        title: "",
    });
    const [ SuccessPopup, setSuccessPopup ] = useState({
        open: false,
        title: ""       
    })

    // checkout popup 
    const CheckoutPaymentModal = () => {
        if(accessToken === "" && is_login === "no") {
            document.getElementById("appbody").classList.toggle("loginModal");
        } else {
            setCheckoutPoup({...checkoutPoup, open: !checkoutPoup?.open, title: "Checkout"});
        }
    };

    return(<AuthLayout>
        <section className="checkoutsection">
        <div className="container">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6">
                        <div className="checkoulistwrps" >
                            {PosterMulti?.cart === 1 ? (<h2>Price - ${SeletedPosterLists[0]?.price}</h2>) : (<h2>Number of posters in cart - {PosterMulti?.cart}</h2>)}
                            <div className="checkoutlistwrapper">
                                {SeletedPosterLists && SeletedPosterLists?.map((elm, index) => {
                                    return(<div className="checkoutlist" key={index}>
                                        <PreviewImageFiles type="image" url={elm?.product_img_path} title={elm.name}/>
                                        <h5>{elm?.name}</h5>
                                    </div>
                                )})}
                            </div>
                        </div>
                    </div>      
                    <div className="col-md-6">
                        <div className="checkoutpaymnt">
                            <h1>Checkout</h1>
                            <h2>Price - ${PosterMulti?.cart === 1 ? SeletedPosterLists[0]?.price : PosterMulti?.amount}</h2>
                            <Button className="btn clickpaymnt" onClick={() => CheckoutPaymentModal()}>Click here to complete checkout</Button>
                            <img src={SecureImage} className="secureimage" alt="SecureImage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* checkout modal start here */}
        {checkoutPoup?.open && (<CheckoutModal selectedProduct={SeletedPosterLists} SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} checkoutPoup={checkoutPoup} setCheckoutPoup={setCheckoutPoup} PosterMulti={PosterMulti} />)}
        {SuccessPopup?.open && (<SuccessModal SuccessPopup={SuccessPopup} setSuccessPopup={setSuccessPopup} />)}
        {(checkoutPoup?.open || SuccessPopup?.open) && (<div className="ModalBackground"></div>)}
    </section>
    </AuthLayout>)
};

export default Checkout;