/* eslint-disable */
import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import GuestLayout from "../Shared/GuestLayout";
import { RESET_PASSWORD_API_URL, ABOUT_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import "../../components/common/fonts/stylesheet.css";
import InfoIcon from "../../assets/img/information-button.png";

const ResetPassword = () => {
    const [ newPassword, setNewPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const PasswordRegex = /^(?=.*[0-9])(?=.*[!@$%^&*])[a-zA-Z0-9!@$%^&*]{6,16}$/;
    const Location = useLocation();
    const Navigate = useNavigate();
    const ResetPasswordId = Location?.pathname?.split("/")[2];
    // error state
    const [ newPasswordError, setNewPasswordError ] = useState("");
    const [ confirmPasswordError, setConfirmPasswordError ] = useState("");
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

    const ResetPasswordFnt = () => {
        if(newPassword !== "" && confirmPassword !== "") {
            if(PasswordRegex.test(newPassword) === true && PasswordRegex.test(confirmPassword) === true && (newPassword === confirmPassword)) {
                const jsonData = JSON.stringify({ password: confirmPassword});
                window.axios.post(RESET_PASSWORD_API_URL + ResetPasswordId, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function (result) {
                    toast.success(result.data.msg);
                    ClearFields();
                    setTimeout(() => {
                        Navigate(ABOUT_URL);
                    }, 1000);
                    document.getElementById("appbody").classList.add("loginModal");
                })  
                .catch(function (result) {
                    // ClearFields();
                    toast.error(result.response.data.error);
                    // setTimeout(() => {
                    //     Navigate(ABOUT_URL);
                    // }, 1000);
                });
                setNewPasswordError("");
                setConfirmPasswordError("");
            } else {
                if(PasswordRegex.test(newPassword) === false) {
                    setNewPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                } else {
                    setNewPasswordError("");
                }
                if(PasswordRegex.test(confirmPassword) === false) {
                    setConfirmPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                } else if(newPassword !== confirmPassword) {
                    setConfirmPasswordError("Your new password and confirmation password do not match.");
                } else {
                    setConfirmPasswordError("");
                }
            }
        } else {
            if(newPassword === "") {
                setNewPasswordError("Password cannot be empty");
            } else if(PasswordRegex.test(newPassword) === false) {
                setNewPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            } else {
                setNewPasswordError("");
            }
            
            if (confirmPassword === "") {
                setConfirmPasswordError("Password cannot be empty");
            } else if(PasswordRegex.test(confirmPassword) === false) {
                setConfirmPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            } else {
                setConfirmPasswordError("");
            }
        }
    };

    // clear field set
    const ClearFields = () => {
        setTimeout(() => {
            setNewPassword(""); 
            setConfirmPassword("");
            setConfirmPasswordError(""); 
            setNewPasswordError("");
        }, 500);
    };

    return(<GuestLayout>
        <div className="loginsignuptabs loginsignuptabsresetpssword">
            <h1>Reset Password</h1>
            <div className="loginPopupMain">
                <div className="loginwitemilformwrps paswordfield">
                    <React.Fragment>
                        <div className="form-group">
                            <label>New Password
                                <div className="passwordnotewrapper">
                                    <ol>
                                        {/* Minimum 8 characters. */}
                                        <li>The alphabet must be between [a-z]</li>
                                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                        <li>At least 1 number or digit between [0-9].</li>
                                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                    </ol>
                                    <Image src={InfoIcon} alt="info icon" />
                                </div>
                            </label>
                            <div className="passwordFieldwrap">
                                <input type="password" className="d-none" />
                                <input type={showPassword ? "text" : "password"} className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                                </span>
                            </div>
                            {newPasswordError && <div className="danger-colorset">{newPasswordError}</div>}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password
                                <div className="passwordnotewrapper">
                                    <ol>
                                        {/* Minimum 8 characters. */}
                                        <li>The alphabet must be between [a-z]</li>
                                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                        <li>At least 1 number or digit between [0-9].</li>
                                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                    </ol>
                                    <Image src={InfoIcon} alt="info icon" />
                                </div>
                            </label>
                            <div className="passwordFieldwrap">
                                <input type="password" className="d-none" />
                                <input type={showConfirmPassword ? "text" : "password"} className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                                </span>
                            </div>
                            {confirmPasswordError && <div className="danger-colorset">{confirmPasswordError}</div>}
                        </div>
                        <Button className="btn loginbtn" onClick={() => ResetPasswordFnt()}>Reset</Button>
                    </React.Fragment>
                </div>
            </div>
        </div>
    </GuestLayout>)
}

export default ResetPassword;