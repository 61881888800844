/* eslint-disable */
import React from "react";
import PosterList from "../common/PosterList/PosterList";
import { useSelector } from "react-redux";
import AuthLayout from "../Shared/AuthLayout";

const SinglePoster = () => {
    const PosterMulti = useSelector((state) => state.allReducers.selectPosterMulti);
    
    return(<AuthLayout>
        <section className="singlepostersection">
        {PosterMulti.cart === "1" ? <h1 className="sectionheader">Single Posters</h1> : <h1 className="sectionheader">Please Choose {PosterMulti.cart} Books</h1>}
        <PosterList />
    </section>
    </AuthLayout>)
}

export default SinglePoster;