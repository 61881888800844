import React from "react";
import AuthLayout from "../Shared/AuthLayout";

const TermsConditions = () => {
    return(<AuthLayout>
        <section className="privacypage">
        <div className="container pravcypolicycontainer">
            <div className="row">
                <div className="col-md-12">
                    <h1>Terms & Conditions</h1>
                    <p>
                       VERSION 1<br />
                       LAST UPDATED: 11/22/2021
                       <br /><br />
                       MOTIVATE ON PAPE WELCOMES YOU.  WE ASK THAT YOU READ THE FOLLOWING TERMS & CONDITIONS, WHICH CONSTITUTE A BINDING CONTRACT THAT COVERS YOUR USE OF THIS SITE.  BY ACCESSING, VIEWING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THESE TERMS.  IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, PLEASE DO NOT USE THIS SITE.
                    </p>
                    <p>
                        SECTION 1 – OVERVIEW 
                        <br /><br />
                        1.1  This website is operated by Motivate on Pape.  Throughout the site, the terms “we”, “us” and “our” refer to Motivate on Pape.  
                        <br /><br />
                        1.2  Any new features or tools which are added to the current website shall also be subject to the Terms & Conditions.  You can review the most current version of the Terms & Conditions at any time on this page.  We reserve the right to update, change or replace any part of these Terms & Conditions by posting updates and/or changes to our website.  It is your responsibility to check this page periodically for changes.  Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes. 
                        <br /><br />
                        1.3  By agreeing to these Terms & Conditions, you represent that you are at least the age of majority in your state or province of residence. 
                        <br /> <br />
                        1.4  The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms. 
                    </p>
                    <p>
                        SECTION 2 – USE OF SITE 
                        <br /><br />
                        2.1  Motivate on Pape is an authorized online seller of digital posters and other digital content.  We provide bulk packages of content for purchase at competitive prices.  Please note, the content on our site comes in Joint Photographic Experts Group (JPEG) format.  It is your responsibility to ensure you have software compatible with such formatting in order to complete your download.
                        <br /><br />
                        2.2  When you purchase content from this website, you are purchasing a limited, non-exclusive, non-transferable, non-sublicensable license to use that content for your own entertainment, educational, or other personal, noncommercial purpose.  You are not authorized or permitted to share or resell any content purchased on this site, and by using our site, you specifically agree not to do so.
                        <br /><br />
                        2.3  When you purchase content from this website, you do not obtain any copyright or other intellectual property interest in the content.  All intellectual property rights remain fully vested in the author, publisher, artist, or original creator of the content.
                        <br /><br />
                        2.4  By using our site, you agree not to alter the form of any content you purchase.  You agree not to remove any copyright information from any content you purchase.  
                        <br /><br />
                        2.5  If you are a copyright owner of any content sold on our site and you believe your intellectual property rights have been violated in connection with a purchase on our site, you may reach us through the contact information contained on our site and make a claim for copyright or other intellectual property infringement.  We respect the rights of our authors, artists, and publishers and wish to assist in the protection of their intellectual property in any way that we can.
                        <br /><br />
                        2.5  When you purchase a poster or other content from our site, you agree not to reproduce, transmit, make it available, adapt, modify, frame, link to, forward, create derivative works based upon it, distribute, disseminate, sell, publish, sublicense, or in any way commingle its content with any third party content without first obtaining permission from the copyright owner of that poster or content. 
                        <br /><br />
                        2.6  Any use of this site other than the uses outlined in these Terms & Conditions is prohibited.  By way of example, you should not use any features of this site that permit communications or postings to post, transmit, display, or otherwise communicate (i) any defamatory, threatening, obscene, harassing, or otherwise unlawful information; (ii) any advertisement, solicitation, spam, chain letter, or other similar type of information; (iii) any encouragement of illegal activity; (iv) unauthorized use or disclosure of private, personally identifiable information of others; or (v) any materials subject to trademark, copyright, or other laws protecting any materials or data of others in the absence of a valid license or other right to do so.
                    </p>
                    <p>
                        SECTION 3 – CUSTOMER ACCOUNTS
                        <br /><br />
                        3.1  Each customer may only have one account.  Accounts may not be shared by multiple customers.  Customers may not transfer or trade purchases between accounts.  Accounts must be owned by individual persons and cannot be owned by businesses.
                        <br /><br />
                        3.2  You are responsible for ensuring that payment information and contact information linked to your account is up to date and accurate.  We have no responsibility to complete any transactions for which the provided account information is not up to date and accurate.
                        <br /><br />
                        3.3  You are responsible to protect your own login information and to protect your account from being accessed by any third parties.  You must not share your account login information with anyone and you must not allow any other person to access your account.
                        <br /><br />
                        3.4  We reserve the right in our sole discretion to close any customer account we deem to be compromised by some security breach, used in violation of these Terms & Conditions, or inactive due to lack of use.
                        <br /><br />
                        3.5  You may choose to close your own account at any time.  You may do so by contacting our customer service representatives.
                    </p>
                    <p>
                        SECTION 4 – SITE CONTENTS AND OWNERSHIP
                        <br /><br />
                        The information contained on this site, including all images, designs, photographs, writings, graphs, data, and other materials (Materials) are solely the property of us and are protected by copyrights, trademarks, trade secrets, or other proprietary rights.  Permission is granted to display, copy, distribute, download, and print portions of this site solely for the purposes of using this site for the authorized uses described in these Terms & Conditions.  You must retain all copyright and other proprietary notices on all copies of the Contents.  You shall comply with all copyright laws worldwide in your use of this website and prevent unauthorized copying of the Contents.  Except as provided in this Notice, we do not grant you any express or implied right in or under any patents, trademarks, copyrights, or trade secret information.
                    </p>
                    <p>
                        SECTION 5 – DISCLAIMER OF WARRANTY
                        <br /><br />
                        5.1  You expressly agree that use of this website is at your sole risk.  Neither us, our affiliates, nor any of our officers, directors, employees, agents, third-party content providers, or licensors (collectively, “Providers”), or the like, warrant that this site will be uninterrupted or error-free; nor do we make any warranty as to the results that may be obtained from the use of this site, or as to the accuracy, completeness, reliability, security, or currency of the Materials.
                        <br /><br />
                        5.2  The Materials may contain errors, omissions, inaccuracies, or outdated information.  Further, we do not warrant reliability of any statement or other information displayed or distributed through the site.  We reserve the right, at our sole discretion, to correct any errors or omissions in any portion of the site.  We may make any other changes to this site, the Materials and the products, programs, services, or prices (if any) described in this site at any time without notice.
                        <br /><br />
                        5.3  THIS SITE AND THE INFORMATION, CONTENT, AND MATERIALS ON THIS SITE ARE PROVIDED ON AN “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS.  WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE, THE CONTENT, INFORMATION, OR THE MATERIALS ON THIS SITE.  TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND, WITH RESPECT TO ANY OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY GOODS OR OTHER PRODUCTS OR SERVICES OFFERED, SOLD, OR DISPLAYED ON THIS SITE OR YOUR USE OF THIS SITE GENERALLY, INCLUDING WARRANTIES OF MERCHANTABILITY, ACCURACY OF INFORMATION, QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.  SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH JURISDICTION’S LAW APPLIES TO THIS AGREEMENT.
                    </p>
                    <p>
                        SECTION 6 – LIMITATION OF LIABILITIES
                        <br /><br />
                        YOU AGREE THAT WE AND OUR AFFILIATES SHALL NOT BE LIABLE FOR ANY DAMAGE, LOSS, OR EXPENSE OF ANY KIND ARISING OUT OF OR RESULTING FROM YOUR POSSESSION OR USE OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT, CONTRACT, OR OTHERWISE.  IN NO EVENT, INCLUDING, WITHOUT LIMITATION, ANY NEGLIGENT ACT, SHALL WE OR ANY OF OUR AFFILIATES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OR CORRUPTION OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR INTERRUPTION OF BUSINESS), ARISING OUT OF OR IN ANY WAY RELATED TO THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION OFFERED, SOLD, OR DISPLAYED ON THIS SITE, YOUR USE OF, OR INABILITY TO USE, THIS SITE GENERALLY, OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF WHETHER WE OR ANY OF OUR AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  BECAUSE SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                    </p>
                    <p>
                        SECTION 7 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
                        <br /><br />
                        You agree to provide current, complete and accurate purchase and account information for all purchases made on our site.  You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. 
                    </p>
                    <p>
                        SECTION 8 – INDEMNIFICATION
                        <br /><br />
                        You agree to indemnify, defend, and hold harmless us, our affiliates, agents, employees, and licensors from and against any and all claims and expenses, including reasonable attorney fees, arising out of or related in any way to your use of the site, use of our software and/or products, violation of this Agreement, violation of any law or regulation, or violation of any proprietary or privacy right.
                    </p>
                    <p>
                        SECTION 9 – PRIVACY POLICY
                        <br /><br />
                        Your submission of personal information through the store is governed by our Privacy Policy, which can be viewed on the site. 
                    </p>
                    <p>
                        SECTION 10 – LIMITATIONS ON CLAIMS
                        <br /><br />
                        Any cause of action you may have with respect to your use of this site must be commenced within one year after the claim or cause of action arises, or it is waived.
                    </p>
                    <p>
                        SECTION 11 – MINORS 
                        <br /><br />
                        We do not knowingly collect, either online or offline, personal information from persons under the age of thirteen.  If you are under the age of majority in your jurisdiction, you may not use this website.
                    </p>
                    <p>
                        SECTION 12 – HYPERLINK DISCLAIMERS 
                        <br /><br />
                        As a convenience to you, we may provide on this site links to websites operated by other entities (collectively the “Linked Sites”).  If you use any Linked Sites, you will leave this site.  If you decide to visit any Linked Site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements.  Linked Sites, regardless of the linking form (e.g., hotlinks, hypertext links, IMG links) are not maintained, controlled, or otherwise governed by us.  The content, accuracy, opinions expressed, and other links provided by Linked Sites are not investigated, verified, monitored, or endorsed by us.  We do not endorse, make any representations regarding, or warrant any information, goods, and/or services appearing and/or offered on any Linked Site, other than linked information authored by us.  Links do not imply that we sponsor, endorse, are affiliated or associated with, or are legally authorized to use any trademark, trade name, logo, or copyright symbol displayed in or accessible through the links, or that any Linked Site is authorized to use any trademark, trade name, logo or copyright symbol of ours or any of our affiliates or subsidiaries.  Except for links to information authored by us, we are neither responsible for nor will we be liable under any theory based on (i) any Linked Site; (ii) any information and/or content found on any Linked Site; or (iii) any site(s) linked to or from any Linked Site.  If you decide to visit any Linked Sites and/or transact any business on them, you do so at your own risk.  We reserve the right to discontinue any Linked Site at any time without prior notice.  Please contact the webmasters of any Linked Sites concerning any information, goods, and/or services appearing on them.
                    </p>
                    <p>
                        SECTION 13 – CONTROLLING LAW, JURISDICTION, AND INTERNATIONAL USERS
                        <br /><br />
                        This Agreement is governed by and shall be construed in accordance with the laws of the State of Michigan, U.S.A., without reference to its conflict-of-law provisions.  We make no representation that the materials are appropriate or available for use outside the United States.  If you access this site from outside the United States, you will be responsible for compliance with all local laws.  You agree to comply with all laws and regulations applicable to your use of this site.  Subject to Section 14, you agree to submit to the personal and exclusive jurisdiction of the state or federal courts located within Kent County, Michigan for any disputes with us arising out of your use of this site.
                    </p>
                    <p>
                        SECTION 14 – ENTIRE AGREEMENT
                        <br /><br />
                        This Agreement constitutes the entire agreement between us and you with respect to this website, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and us with respect to this website.  A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based on or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  If for any reason a court of competent jurisdiction finds any provision of this Agreement or portion of it to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effectuate the intent of this Agreement, and the remainder of this Agreement shall continue in full force and effect.  No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.
                    </p>
                    <p>
                        SECTION 15 – ARBITRATION AGREEMENT
                        <br /><br />
                        In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms & Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.  The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction.  In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms & Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees.  The parties agree to arbitrate all disputes and claims in regards to these Terms & Conditions or any disputes arising as a result of these Terms & Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms & Conditions.  The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator.  This arbitration provision shall survive the termination of these Terms & Conditions.
                    </p>
                    <p>
                        SECTION 16 - SEVERABILITY
                        <br /><br />
                        In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms & Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.  The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction.  In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms & Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees.  The parties agree to arbitrate all disputes and claims in regards to these Terms & Conditions or any disputes arising as a result of these Terms & Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms & Conditions.  The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator.  This arbitration provision shall survive the termination of these Terms & Conditions.
                    </p>
                    <p>
                        SECTION 17 – CLASS ACTION WAIVER
                        <br /><br />
                        Any arbitration under these Terms & Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.  Further, unless both you and we agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
                    </p>
                    <p>
                        SECTION 18 – NO THIRD-PARTY BENEFICIARIES
                        <br /><br />
                        Nothing in these Terms & Conditions shall create any third-party rights, nor shall anything in these Terms & Conditions bind us to any sort of covenant, agreement, or contract with a third party.
                    </p>
                    <p>
                        SECTION 19 – MODIFICATIONS TO AGREEMENT
                        <br /><br />
                        We may revise this Agreement at any time and you agree to be bound by the revised Agreement.  Any such modifications will become effective on the date they are first posted to this site.  It is your responsibility to return to this Agreement from time to time to review the most current Terms & Conditions.  We do not and will not assume any obligation to notify you of changes to this Agreement.
                    </p>
                    <p>
                        SECTION 20 – ELECTRONIC COMMUNICATIONS AND ELECTRONIC SIGNATURES
                        <br /><br />
                        You agree to be bound by any affirmation, assent, or agreement you transmit through this website, including but not limited to any consent you give to receive communications from us solely through electronic transmission.  You agree that when in the future you click on an “I agree,” “I consent,” or other similarly worded “button” or entry field with your touchscreen, mouse, keystroke, or otherwise, your agreement or consent will be legally binding and enforceable and shall be the legal equivalent of your handwritten signature.
                    </p>
                    <p>
                        SECTION 21 – FORCE MAJEURE
                        <br /><br />
                        Neither party to this agreement shall be liable or responsible to the other party for any failure or delay in fulfilling or performing any term of this agreement when such failure or delay is caused by events beyond the affected party’s reasonable control.  This includes, but is not limited to (1) acts of God; (2) flood, fire, earthquake, or explosion; (3) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot, or other civil unrest; (4) changes in the law or regulatory enforcement which do not allow for continued operations of either party; (5) actions, embargoes, or blockades in effect on or after the date of this agreement; and (6) national or regional emergency.  The party suffering a Force Majeure event shall give prompt notice to the other party, stating the period of time the occurrence is expected to continue, and shall use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure event are minimized.  Section XI does not apply to obligations to make payments for services rendered and/or revenue collected prior to the Force Majeure event.
                    </p>
                    <p>
                        SECTION 22 – ASSIGNMENT AND DELEGATION
                        <br /><br />
                        You may not assign any rights or delegate any duties granted to you under this agreement.
                    </p>
                    <p>
                        SECTION 23 – NON-WAIVER 
                        <br /><br />
                        No waiver by any party of any of the provisions of this agreement shall be effective unless set forth in writing and signed by the waiving party.  No waiver by any party shall operate or be construed as a waiver regarding any failure, breach, or default not expressly identified by such written waiver, whether of a similar or different character, and whether occurring before or after that waiver.  No failure to exercise, or delay in exercising, or partial exercise of any right or remedy arising under this agreement shall operate or be construed as a waiver, nor shall it preclude any other or further exercise of the waiver or the exercise of any other right or remedy.
                    </p>
                </div>
            </div>
        </div>
    </section>
    </AuthLayout>)
}

export default TermsConditions;