/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHECK_OUT_SINGLE, CHECK_OUT_DOUBLE, GET_PRODUCT_API_URL, ABOUT_URL } from "../../Shared/constant";
import { AddToCartPosters, getSinglePosters, getAccessToken, checkLogin, getUser } from "../../../redux/actions";
import BookPreviewModal from "../BookPreviewModal";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import PreviewImageFiles from "../PreviewImageFiles";

const PosterList = () => {
    const { singlposterslist, selectPosterMulti } = useSelector((state) => state.allReducers);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [ SelectedPosterState, setSelectedPosterState ] = useState([]);
    const [ PreviewBookModalState, setPreviewBookModalState ] = useState(false);
    const [ listLimit, setListLimit ] = useState(20);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        window.axios.get(`${GET_PRODUCT_API_URL}/${listLimit}/${currentPage}/none`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            dispatch(getSinglePosters(result.data.data));
            setPaginationLength(result.data.pagination);
        })  
        .catch(function (result) {
            dispatch(getSinglePosters([]));
            setPaginationLength({});
            if(result?.response?.data?.detail === "Token expired.") {
                AccessTokenCheckLogout();
            };
        });
    }, [ listLimit, currentPage ]);

    // add to cart function
    let PostersVar = [...SelectedPosterState];
    const AddToCart = (elm) => {
        PostersVar.push(elm);
        setSelectedPosterState(PostersVar);
        if(selectPosterMulti.cart === 1) {
            dispatch(AddToCartPosters(PostersVar));
            setTimeout(() => Navigate(CHECK_OUT_SINGLE), 100);
        } else if(selectPosterMulti.cart === PostersVar.length.toString()) {
            dispatch(AddToCartPosters(PostersVar));
            setTimeout(() => Navigate(CHECK_OUT_DOUBLE), 100);
        } 
    };

    // get selected posters id
    const MemberListArray = [];
    SelectedPosterState.forEach(element => {
        MemberListArray.push(element.id);
    });

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() =>  Navigate(ABOUT_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    return(<div className="posterlistwraps">
        <div className="container-fluid custom-container-fluid">
            <div className="col-md-12">
                <div className="row">
                    <div className="poserrbooksrow">
                        {singlposterslist?.length > 0 ? singlposterslist?.map((elm, index) => {
                            return(<div className="col-md-3 col-sm-6 col-xs-12" style={{ padding: "0px 20px" }} key={index}>
                                <div className="posterblogswrp">
                                    <PreviewImageFiles type="image" url={elm?.product_img_path} title={elm.name}/>
                                    <div className="posterblogswrpdetails">
                                        <h5>{elm?.name}</h5>
                                        <div className="postaddcart">
                                            <h4>${elm?.price}</h4>
                                            <Button 
                                                className="btn addtocart" 
                                                onClick={() => AddToCart(elm)}
                                                disabled={MemberListArray.includes(elm?.id) === true ? true : false}
                                            >Add to cart</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }) : (<h4 className="notdatafound">No Posters Found</h4>)}
                    </div>
                </div>
            </div>
        </div>
        <div className='paginationCustom'>
            <Pagination
                currentPage={parseInt(currentPage)}
                itemsPerPage={parseInt(paginationLength?.record_limit)}
                onPageChange={(pageNumber) => currentFunction(pageNumber)}
                totalItems={parseInt(paginationLength?.total_records)}
                pageNeighbours={1}
            />
        </div>
        {PreviewBookModalState && (<BookPreviewModal bookSummary={bookSummary} setPreviewBookModalState={setPreviewBookModalState} />)}
        {PreviewBookModalState && (<div className="modalBckgrounds" onClick={() => setPreviewBookModalState(false)}></div>)}
    </div>);
}

export default PosterList;