export const ActionTypes = {
    GET_SIGNLE_POSTER: "GET_SIGNLE_POSTER",
    ADD_TO_CART: "ADD_TO_CART",
    SELECT_POSTER_MULTI: "SELECT_POSTER_MULTI",
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    CARD_DETAILS: "CARD_DETAILS",
    PRESELECT_MULTI_POSTER: "PRESELECT_MULTI_POSTER",
    IS_LOGIN: "IS_LOGIN",
    GET_USER: "GET_USER",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    UPDATE_USER: "UPDATE_USER",
    UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
    ACCOUNT_NAVIGATE: "ACCOUNT_NAVIGATE",
    GET_MY_PRODUCT: "GET_MY_PRODUCT",
    ADD_MY_PRODUCT: "ADD_MY_PRODUCT",
    GET_PRODUCT_ID_WISE: "GET_PRODUCT_ID_WISE",
    GET_FIRST_10_PRODUCT: "GET_FIRST_10_PRODUCT",
    GET_UUID: "GET_UUID",
    GET_GEO_LOCATE_RESPONSE: "GET_GEO_LOCATE_RESPONSE",
    SET_LOGOUT_USER: "SET_LOGOUT_USER"
};

export const preSelectMultiPoster = (posters) => {
    return {
        type: ActionTypes.PRESELECT_MULTI_POSTER,
        payload: posters,
    }
};

export const getGeoLocateResponse = (responses) => {
    return {
        type: ActionTypes.GET_GEO_LOCATE_RESPONSE,
        payload: responses,
    }
};

export const getUUID = (id) => {
    return {
        type: ActionTypes.GET_UUID,
        payload: id,
    }
}

export const CallLogoutUser = () =>{
    return {
        type: ActionTypes.SET_LOGOUT_USER,
    }
}

export const getSinglePosters = (posters) => {
    return {
        type: ActionTypes.GET_SIGNLE_POSTER,
        payload: posters,
    }
};

export const getFirstTenPosters = (posters) => {
    return {
        type: ActionTypes.GET_FIRST_10_PRODUCT,
        payload: posters,
    }
};

export const AddToCartPosters = (posters) => {
    return {
        type: ActionTypes.ADD_TO_CART,
        payload: posters,
    }
};

export const SelectMultiPosters = (posters) => {
    return {
        type: ActionTypes.SELECT_POSTER_MULTI,
        payload: posters,
    }
}

export const customerDetailsGet = (customer) => {
    return {
        type: ActionTypes.CUSTOMER_DETAILS,
        payload: customer,
    }
}

export const cardDetailsGet = (card) => {
    return {
        type: ActionTypes.CARD_DETAILS,
        payload: card,
    }
}

export const accountNavigate = (link) => {
    return {
        type: ActionTypes.ACCOUNT_NAVIGATE,
        payload: link,
    }
}

export const GetMyProducts = (products) => {
    return {
        type: ActionTypes.GET_MY_PRODUCT,
        payload: products,
    }
}

export const GetProductsIdWise = (products) => {
    return {
        type: ActionTypes.GET_PRODUCT_ID_WISE,
        payload: products,
    }
}

export const AddMyProduct = (product) => {
    return {
        type: ActionTypes.ADD_MY_PRODUCT,
        payload: product,
    }
}

export const GetCategory = (categorys) => {
    return {
        type: ActionTypes.GET_CATEGORY,
        payload: categorys,
    }
}

export const getIdWiseCategoryList = (category) => {
    return {
        type: ActionTypes.ID_WISE_CATEGORY,
        payload: category,
    }
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getUser = (user) => {
    return {
        type: ActionTypes.GET_USER,
        payload: user,
    }
}

export const updateProfilePic = (profile) => {
    return {
        type: ActionTypes.UPDATE_PROFILE_PIC,
        payload: profile,
    }
}

export const updateUser = (user) => {
    return {
        type: ActionTypes.UPDATE_USER,
        payload: user,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.ACCESS_TOKEN,
        payload: token,
    }
}

