/* eslint-disable */
import React, { useState } from "react";
import CustomMendotoryMsg from "../common/CustomMendotoryMsg";
import SucessIcon from "../../assets/img/success.svg";
import CountrySelectInput from "../common/CountrySelectInput/CountryMobileInput";
import { Button } from "react-bootstrap";
import AuthLayout from "../Shared/AuthLayout";
import { CONTACT_US_API_URL, ABOUT_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAccessToken, checkLogin, getUser } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';

const Contact = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "1",
        number: "",
    });
    const [ CustomError, setCustomError ] = useState(false);
    const [ MobileError, setMobileError ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const [ loader, setLoader ] = useState(false);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");

    const SubmitContact = () => {
        document.getElementById("contactflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.toString()?.length === 10 && message !== "") {
            if(EmailRegex.test(email) === true) {
                setLoader(true);
                setCustomError(false);
                setErrorEmail("");
                const jsonData = JSON.stringify({ 
                    "first_name" : fname, 
                    "last_name" : lname, 
                    "email" : email.toLowerCase(),
                    "phone" : MobileNo.countrycode + " " + MobileNo.number,
                    "message" : message,
                });
                window.axios.post(CONTACT_US_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).then(function (result) {
                    setTimeout(()=> {
                        setLoader(false);
                        toast.success(result.data.msg);
                        SubmitClearForm();
                        setMobileError("");
                    }, 100);
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                    setLoader(false);
                    if(result?.response?.data?.detail === "Token expired.") {
                        AccessTokenCheckLogout();
                    };
                });
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.toString()?.length) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    };

    // submit clear function
    const SubmitClearForm = () => {
        setTimeout(() => {
            setFname("");
            setLname("");
            setEmail("");
            setMessage("");
            setMobileNo({...MobileNo, number: ""});
        }, 500);
    }

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() =>  Navigate(ABOUT_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    return(<AuthLayout>
        <section className="privacypage RefundPolicypage">
    <div className="container pravcypolicycontainer">
        <div className="row">
            <div className="col-md-12">
                <div className="contactusform">
                    <h1>Contact</h1>
                    <h4>You're welcome to contact us with any inquiry</h4>
                    <div className="contactDetails">
                        <div><a href="tel: 616-465-4100">Tel: 616-465-4100</a></div> 
                        {/* | 
                        <div><a href="mailto: info@my-domain.com">info@my-domain.com</a></div> */}
                    </div>
                    <div className="paymentformsWraps">
                    <div className="rowcustom">
                            <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                <label>First Name</label>
                                <div className="form-groupfiled">
                                    <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                    {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom">
                            <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                                <label>Last Name</label>
                                <div className="form-groupfiled">
                                    <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" />
                                    {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom">
                            <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                                <label>Email</label>
                                <div className="form-groupfiled">
                                    <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value.trim())} placeholder="Enter Email" />
                                    {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom">
                            <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                <label>Phone</label>
                                <div className="form-groupfiled">
                                    <CountrySelectInput 
                                        MobileNo={MobileNo}
                                        setMobileNo={setMobileNo}
                                        id={"contactflag"}
                                    />
                                    {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom">
                            <div className={CustomError && message === "" ? "form-group error" : "form-group"}>
                                <label>Message</label>
                                <div className="form-groupfiled">
                                    <textarea type="text" className="form-control" id="input" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type your message here..." />
                                    {message !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && message === "" && <CustomMendotoryMsg value={message} label={"Message"} />}
                                </div>
                            </div>
                        </div>
                        <div className="contactussubmit">
                            <Button className="btn formcomnbtn" disabled={loader} onClick={() => SubmitContact()}>
                                {loader && (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>)}
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</AuthLayout>)
}

export default Contact;