/* eslint-disable */
import React from "react";
import DigitalPosters from "./DigitalPosters";
import PurchesPoster from "./PurchesPoster";
import AuthLayout from "../Shared/AuthLayout";

const About = () => {

    return(<AuthLayout>
            <section className="aboutuspage">
            <DigitalPosters />
            <PurchesPoster />
        </section>
    </AuthLayout>)
}

export default About;