/* eslint-disable */
import React, { useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import PreviewImageFiles from "../common/PreviewImageFiles";

const DigitalPosters = () => {
    const [ loveIt, setloveIt ] = useState([]);
    const PosterLists = useSelector((state) => state.allReducers.firsttenPosterlist);
    const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 1500,
        margin: 20,
        centerPadding: "60px",
        autoplay: true,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        rtl: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerPadding: "60px",
                infinite: true,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                centerPadding: "60px",
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                centerPadding: "60px",
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    const SelectUserFn = (user) => {
        const checkBoxCheck = document.querySelector("#loveid" + user.id);   
        let CheckboxLikes = [...loveIt];
        if(checkBoxCheck.checked === true) {
            CheckboxLikes.push(user);
            setloveIt(CheckboxLikes);
        } else {
          let selectMembervarnew = loveIt.filter((elm, _index) => {
              if(elm?.id !== user?.id) {
                  return elm;
              }
          })
          setloveIt(selectMembervarnew);
        }
    }   
    
    return(<section className="digitalposters">
        <div className="videoOvarlay"> </div>
        <div className="digitalposterssliders">
            <div className="commonheader">
                <h1>Motivate On Pape</h1>
                <h4>Digital Posters</h4>
            </div>
            <Slider {...settings}>
                {PosterLists && PosterLists.map((elm, index) => {
                    return(<div className="posterwrapps" key={index}>
                        <PreviewImageFiles type="image" url={elm?.product_img_path} title={elm.name}/>
                        <div className="posterlistovarlay" >
                          <div className="customCheckBoxNew">
                              <label className="containernew" >
                                  <input 
                                      type="checkbox" id={"loveid" + elm?.id}  
                                      onChange={() => SelectUserFn(elm)}
                                  />
                                  <span className="checkmark"></span>
                              </label>
                              {/* {loveIt && "1"} */}
                          </div>
                        </div>
                    </div>)
                })}
            </Slider>
        </div>
    </section>)
}

export default DigitalPosters;