/* eslint-disable */
import React from "react";
import PurchesSingleImage from "../../assets/img/posters/1.webp";
import PurchesBundleImage1 from "../../assets/img/posters/15.webp";
import PurchesBundleImage2 from "../../assets/img/posters/3.webp";
import { Link } from "react-router-dom";
import { SINGLE_OR_CHOOSE_URL, SINGLE_POSTER } from '../../components/Shared/constant';

const PurchesPoster = () => {
    return(<section className="PurchesPostersction">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="purchesswraps">
                        <div className="row">
                            <div className="col-6">
                                <p className="purchespassage">
                                    At Motivate on Pape, we believe the best way to self-actualization and improvement is through consistent motivation and self love. That’s why we created Motivate on Pape – the motivational poster site full of digital posters that push you towards being the best version of yourself.
                                    <br /><br />
                                    Motivate on Pape wants to help motivate you and give you the kick-start you need through your self improvement journey. Our digital posters serve as a daily reminder to improve your mindset with thought-provoking, empowering, and uplifting words and images. 
                                    <br /><br />
                                    We offer motivational posters for sale as singular posters or in bundles. We keep our digital posters at an affordable price because we believe that everyone deserves true happiness and should strive towards self improvement in their lives. Our goal is to assist you in unlocking your true potential and living a life you love. 
                                </p>
                            </div>
                            <div className="col-6">
                                <div className="purchessingletypes">
                                    <img src={PurchesSingleImage} alt="Purches Single Image" />
                                    <Link className="btn Purchasebtn" to={SINGLE_POSTER} >Purchase Single Poster</Link>
                                </div>
                                <div className="purchesBundletypes">
                                    <div className="purchesBundleGroups">
                                        <img src={PurchesBundleImage2} alt="Purches Single Image" />
                                        <img src={PurchesSingleImage} alt="Purches Single Image" />
                                        <img src={PurchesBundleImage1} alt="Purches Single Image" />
                                    </div>
                                    <Link className="btn Purchasebtn" to={SINGLE_OR_CHOOSE_URL} >Purchase Bundle of Posters</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}

export default PurchesPoster;