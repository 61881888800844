import Logo from "../../assets/img/logo.webp";
export const LOGO_URL = Logo;
export const ABOUT_URL = "/";
export const SINGLE_POSTER = "/single-poster";
export const SINGLE_OR_CHOOSE_URL = "/preselected-or-choose";
export const CHECK_OUT_SINGLE = "/checkout-single";
export const CHECK_OUT_DOUBLE = "/checkout-double";
export const CHOOSE_BUNDLE_AMOUNT = "/choose-bundle-amount";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-conditions";
export const REFUND_POLICY = "/refund-policy";
export const CONTACT = "/contact";
export const MY_ACCOUNT = "/my-account";
export const RESET_PASSWORD = "/reset-password";

// Api urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "user/login";
export const SIGN_UP_API_URL = API_URL + "user/sign-up";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const RESET_PASSWORD_API_URL = API_URL + "user/create-password/";
export const FILE_UPLOAD_API_URL = API_URL + "file/profile-upload";
export const PROFILE_UPDATE_API_URL = API_URL + "user/profile-update";
export const GET_PROFILE_API_URL = API_URL + "user/get";
export const PROFILE_UPLOAD_API_URL = API_URL + "file/profile-upload";
export const GET_PRODUCT_API_URL = API_URL + "product/list";
export const PLACE_PRODUCT_ORDER_API_URL = API_URL + "user/place-order";
export const PAYMENT_PLACE_ORDER_API_URL = API_URL + "user/payment";
export const GET_MY_PRODUCT = API_URL + "user/my-products";
export const PRE_SELECTED_PRODUCT = API_URL + "product/pre-selected/";
export const CONTACT_US_API_URL = API_URL + "contact-us";
export const SUBSCRIBE_API_URL = API_URL + "subscribe";
export const SOCIAL_FIREBASE_API_URL = API_URL + "user/social-auth";
export const USER_DATA_DETECTION = "/user-data-deletion";

export const GET_GEO_LOCATE_LICENSE = API_URL + "user/geocomply/license";
export const GET_GEO_LOCATE_PERMISSION_LICENSE = API_URL + "user/geocomply/permission";