import React from "react";
import dummyImage from "../../assets/img/dummyimage.png";
import dummyProductImage from "../../assets/img/DefaultProduct.jpg";

const PreviewImageFiles = (props) => {
    const { type, url, title} = props;
    if(type === "image") {
        return(<div className="previewImage">
            {/* <img src={process.env.REACT_APP_IMAGE_PREVIEW + url.replace("file/view/thumbnail", "thumbnails") +`.${title?.split(".")[1]}`} alt={title} /> */}
            <img src={url} alt={title} onError={(e)=> {e.target.src = dummyImage}} />
        </div>)
    } else {
        return(<div className="previewImage">
            {url === undefined ? (<img src={dummyProductImage} alt="dummyImage" />) : (<img src={url} alt={title} onError={(e)=> {e.target.src = dummyImage}} />)} 
        </div>)
    }
}

export default PreviewImageFiles;