/* eslint-disable */
import { ActionTypes } from "../../redux/actions";

const initailData = {
    firsttenPosterlist: [],
    singlposterslist: [],
    selectedPosters: [],
    selectPosterMulti: {
        cart: 1,
        amount: 3.99,
    },
    PreselectMultiPoster: false,
    is_login: "no",
    profiledata: {},
    accessToken: "",
    customerDetail : {},
    cardDetails: {},
    accountUrl: "my-books",
    myproducts: [],
    productidObjects: {},
    uuId: "",
    GeoLocationRespo: {
        type: "",
        data: {}
    },
    checkCache: ""
}

export const posterReducers = (state = initailData, action) => {
    switch(action.type) {
        case ActionTypes.GET_FIRST_10_PRODUCT :
            return { 
                ...state, 
                firsttenPosterlist : action.payload, 
            };
        case ActionTypes.GET_UUID :
            return { 
                ...state, 
                uuId : action.payload, 
            };
        case ActionTypes.GET_GEO_LOCATE_RESPONSE :
            return { 
                ...state, 
                GeoLocationRespo : action.payload, 
            };
        case ActionTypes.GET_SIGNLE_POSTER :
            return { 
                ...state, 
                singlposterslist : action.payload, 
            };
        case ActionTypes.ADD_TO_CART :
            return {
                ...state,
                selectedPosters : action.payload,
            }
        case ActionTypes.PRESELECT_MULTI_POSTER :
            return {
                ...state,
                PreselectMultiPoster : action.payload
            }
        case ActionTypes.SELECT_POSTER_MULTI :
            return {
                ...state,
                selectPosterMulti : {
                    cart: action.payload.cart,
                    amount : action.payload.amount,
                },
            }
        case ActionTypes.CUSTOMER_DETAILS :
            return { 
                ...state, 
                customerDetail : action.payload, 
            };
        case ActionTypes.CARD_DETAILS :
            return { 
                ...state, 
                cardDetails : action.payload, 
            };
        case ActionTypes.IS_LOGIN :
            return { 
                ...state, 
                is_login : action.payload, 
            };
        case ActionTypes.ACCOUNT_NAVIGATE :
            return {
                ...state,
                accountUrl: action.payload,
            };
        case ActionTypes.GET_USER :
            return { 
                ...state, 
                profiledata: action.payload, 
            };
        case ActionTypes.GET_MY_PRODUCT :
            return { 
                ...state, 
                myproducts: action.payload.reverse(), 
            };
        case ActionTypes.ADD_MY_PRODUCT :
            const products = [...state.myproducts];
            products.unshift(action.payload);
            return { 
                ...state, 
                myproducts: products, 
            };
        case ActionTypes.GET_PRODUCT_ID_WISE :
            const productlists = [...action.payload];
            let arrayproducts = {};
            productlists && productlists.map((elm) => {
                arrayproducts[elm.id] = elm;
            });

            return {
                ...state,
                productidObjects : arrayproducts
            };
        case ActionTypes.UPDATE_USER :
            return { 
                ...state, 
                profiledata: action.payload, 
            };
        case ActionTypes.GET_CATEGORY : 
            return {
                ...state,
                categorylist: action.payload,
            }
        case ActionTypes.UPDATE_PROFILE_PIC : 
            const OldProfile = state.profiledata;
            OldProfile.user_avatar_path = action.payload;

            return {
                ...state,
                profiledata : OldProfile,
            }
        case ActionTypes.ACCESS_TOKEN :
            return { 
                ...state, 
                accessToken : action.payload, 
            };
        case ActionTypes.SET_LOGOUT_USER :
            return initailData;
        default:
            return state;
    }
}