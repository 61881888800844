import React from "react";
import AuthLayout from "../Shared/AuthLayout";

const RefundPolicy = () => {
    return(<AuthLayout>
        <section className="privacypage RefundPolicypage">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Purchase & Refund Policy</h1>
                        <p>Your Digital Posters will be delivered to you by email notification.  The digital Posters are also stored on your customer account and available for download upon login.
                        <br /><br />
                        ALL SALES ARE FINAL on digital products.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </AuthLayout>)
}

export default RefundPolicy;